import { SchoolScreen, getSchoolServerSideProps, IWebServerProps } from '@gf/cross-platform-lib';
import { NextApiResponse } from 'next';
export default SchoolScreen;

export async function getServerSideProps({
  res,
  query
}: {
  res: NextApiResponse;
  query: { id: string };
}): Promise<IWebServerProps> {
  try {
    const { id: schoolId } = query;
    const rs = await getSchoolServerSideProps(schoolId);
    res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59');
    return rs;
  } catch (e) {
    return {
      props: {}
    };
  }
}
